import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {applyMiddleware, compose, combineReducers, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {Provider} from 'react-redux'
import {createLogger} from "redux-logger";
import * as serviceWorker from './serviceWorker';
import {searchCustomers, requestCustomers} from "./reducers";
import Site from "./containers/Site";

const logger = createLogger();


const rootReducer = combineReducers({searchCustomers, requestCustomers});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunkMiddleware, logger)
));

ReactDOM.render(
    <Provider store={store}>
        <Site/>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
