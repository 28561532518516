import React, {Component} from "react";
import './SignInForm.css'
import GoogleLogin from 'react-google-login';

export class SignInForm extends Component {

    signup(res) {
        const googleresponse = {
            Name: res.profileObj.name,
            email: res.profileObj.email,
            token: res.googleId,
            Image: res.profileObj.imageUrl,
            ProviderId: 'Google'
        };
        debugger;
        /*axios.post('http://localhost:60200/Api/Login/SocialmediaData', googleresponse)
            .then((result) => {
                let responseJson = result;
                sessionStorage.setItem("userData", JSON.stringify(result));
                this.props.history.push('/Dashboard')
            });

         */
    };

    render() {
        const responseGoogle = (response) => {
            console.log(response);
            var res = response.profileObj;
            console.log(res);
            debugger;
            this.signup(response);
        }
        return (
            <main className="pa4 white-80">
                <GoogleLogin clientId="788786912619-k4tb19vgofvmn97q1vsti1u8fnf8j6pa.apps.googleusercontent.com"
                             buttonText="Login with Google"
                             onSuccess={responseGoogle}
                             onFailure={responseGoogle}></GoogleLogin>
            </main>
        );
    }
}

export default SignInForm;
