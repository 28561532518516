import React from "react";
import SubscriptionCard from "./SubscriptionCard";

const PlanBox = () =>
    <ul id={"subscription-plans"} className={"flex"}>
        <SubscriptionCard name={"1 month"}/>
        <SubscriptionCard name={"1 year"}/>
        <SubscriptionCard name={"2 years"}/>
    </ul>;

export default PlanBox
