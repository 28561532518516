import React from "react";
import SubscriptionCard from "./SubscriptionCard";

const CardList = (props) => {

    return (
        <div>
            {
                props.customers.map((user, i) => {
                    return (
                        <SubscriptionCard
                            key={i}
                            id={user.id}
                            name={user.name}
                            manager={user.manager}/>
                    )
                })
            }
        </div>
    );
};

export default CardList;
