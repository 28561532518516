import React from "react";
import SearchBox from "../../components/SearchBox";
import Scroll from "../../components/Scroll";
import CardList from "../../components/CardList";
import {connect} from "react-redux";
import {requestCustomers, setSearchField} from "../../actions";


class Customers extends React.Component {

    componentDidMount() {
        this.props.onRequestCustomers('согласие')
    }

    render() {
        const {onSearchChange, customers} = this.props;
        return (
            <div className={'tc'}>
                <h1>Клиенты</h1>
                    <SearchBox searchChange={onSearchChange}/>
                    <Scroll>
                        {this.props.isPending ?
                            <h1>Loading</h1> :
                            (
                                <CardList customers={customers}/>
                            )
                        }
                    </Scroll>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSearchChange: (event) => dispatch(requestCustomers(event.target.value)),
        onRequestCustomers: (text) => dispatch(requestCustomers(text))
    }
};

const mapStateToProps = (state) => {
    return {
        searchField: state.searchCustomers.searchField,
        customers: state.requestCustomers.customers,
        isPending: state.requestCustomers.isPending,
        error: state.requestCustomers.error

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Customers)
