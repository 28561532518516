import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom"

const HowItWorks = () =>
    <main className="Site">
        <Typography variant={"h1"}>How it works</Typography>
        <Typography variant={"h2"}>General use</Typography>
        <ol>
            <li>You <Link component={RouterLink} to={"/charity"}>Sign in</Link> and link your social network profile</li>
            <li>You pay for subscription and gain full access</li>
            <li>
                <ol>
                    <li>We transfer 33% of all subscription payments to <Link component={RouterLink} to={"/charity"}>charity
                        funds</Link></li>
                    <li>We distribute 33% of all subscription payments across all Authors whose content has been
                        viewed
                    </li>
                    <li>We invest 34% of all subscription payments in our business and infrastructure</li>
                </ol>
            </li>
            <li>You search for Content and Authors</li>
            <li>You donate to Authors and buy Goods</li>
            <li>We take commission from any payment according to <Link component={RouterLink} to={"/charity"}>Terms of
                Service</Link></li>
            <li>You got an analytics of charity transfers</li>
        </ol>
        <Typography variant={"h2"}>Become an Author</Typography>
        <ol>
            <li>You <Link component={RouterLink} to={"/charity"}>Sign in</Link> and (optionally) link your social network profile</li>
            <li>You provide us with proof of incapacity</li>
            <li>We check your data and confirm Author status</li>
            <li>You get access to private Social Network</li>
            <li>You create Content and Goods then publish it</li>
            <li>We show your Content to other and sell your stuff</li>
            <li>You get a money from subscriptions and shop</li>
            <li>You get a Social Network where you can communicate with other Authors</li>
        </ol>
    </main>;

export default HowItWorks
