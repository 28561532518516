import React from "react";

const ContactUsForm = () => {
    return (
        <form className="measure center">
            <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                <legend className="f4 fw6 ph0 mh0">Contact Us</legend>
                <div className="mt3">
                    <label className="db fw6 lh-copy f6" htmlFor="email-address">Phone</label>
                    <input className="pa2 input-reset bg-transparent hover-bg-black-10 hover-white w-100"
                           type="phone"
                           name="phone" id="phone"/>
                </div>

            </fieldset>
            <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib"
                   type="submit"
                   id="submit-button"
                   value="Contact Us"/>
        </form>
    )
};

export default ContactUsForm
