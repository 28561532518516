import React from "react";
import SignInForm from "../../components/SignInForm";

const SignIn = () => {
    return (
        <div className="Site">
            <SignInForm/>
        </div>
    );
}

export default SignIn
