import React from "react";
import ContactUsForm from "../../components/ContactUsForm";

const ContactUs = () =>
    <div className="Site">
        <main className="pa4 white-80">
            <ContactUsForm/>
        </main>
    </div>;

export default ContactUs
