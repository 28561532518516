import React from "react";
import PlanBox from "../../components/PlanBox";
import 'typeface-roboto';

const Pricing = () =>
    <main className="Site">
            <h1>Choose your subscription plan</h1>
            <PlanBox id={"subscription-plans"} />
    </main>;

export default Pricing
