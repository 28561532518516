import React from "react";


const SearchBox = (props) => {
        return (
            <div className={'pa2'}>
                <input
                    type={'search'}
                    placeholder={'search customers'}
                    onChange={props.searchChange}
                />
            </div>
        );
};

export default SearchBox;
