import {CHANGE_SEARCH_FIELD, REQUEST_CUSTOMERS_FAILED, REQUEST_CUSTOMERS_PENDING, REQUEST_CUSTOMERS_SUCCESS} from "./constants";

const initialStateSearch = {
    searchField: ''
};

const initialStateRequest = {
    isPending: false,
    customers: [],
    error: ''
};

export const searchCustomers = (state = initialStateSearch, action = {}) => {
    switch (action.type) {
        case CHANGE_SEARCH_FIELD:
            return Object.assign({}, state, {searchField: action.payload});
        default:
            return state
    }
};

export const requestCustomers = (state=initialStateRequest, action={}) => {
    switch (action.type) {
        case REQUEST_CUSTOMERS_PENDING:
            return Object.assign({}, state, {isPending: true});
        case REQUEST_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, {customers: action.payload, isPending: false});
        case REQUEST_CUSTOMERS_FAILED:
            return Object.assign({}, state, {error: action.payload, isPending: false});
        default:
            return state
    }
};
