import {
    CHANGE_SEARCH_FIELD,
    REQUEST_CUSTOMERS_PENDING,
    REQUEST_CUSTOMERS_SUCCESS,
    REQUEST_CUSTOMERS_FAILED
} from "./constants";

// action creator
export const setSearchField = (text) => ({
    type: CHANGE_SEARCH_FIELD,
    payload: text
});

export const requestCustomers = q => (dispatch) => {
    dispatch({type: REQUEST_CUSTOMERS_PENDING})
    fetch('https://melniki.pro/api/customers?query='+q)
        .then(response => response.json())
        .then(data => dispatch({type: REQUEST_CUSTOMERS_SUCCESS, payload: data}))
        .catch(error => dispatch({type: REQUEST_CUSTOMERS_FAILED, payload: error}))
};
