import React from "react";
import {Link as RouterLink} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

const Welcome = () =>
    <main className={"Site"}>
        <Typography variant="h1">Welcome to Melniki</Typography>
        <Typography variant="h2">Enjoy unlimited access to exclusive content</Typography>
        <Typography variant="body1">
            <Button variant="contained" color="primary" component={RouterLink} to="/sign-in">Subscribe</Button>
            <span> or </span>
            <Button color="secondary" component={RouterLink} to="/contact-us">Ask Us a question</Button>
        </Typography>
    </main>;

export default Welcome
