import React, {Component} from "react";
import {connect} from "react-redux";
import "./Site.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Welcome from "./pages/Welcome";

import SignIn from './pages/SignIn'
import HowItWorks from './pages/HowItWorks'
import ContactUs from './pages/ContactUs'
import Pricing from './pages/Pricing'
import Customers from "./pages/Customers";
import Header from "./layout/Header";
import Container from "@material-ui/core/Container";
import { YMInitializer } from 'react-yandex-metrika';
import Privacy from "./pages/Privacy";

class Site extends Component {

    render() {
        return (
            <React.Fragment>
                <Router>
                    <Container maxWidth="lg" disableGutters={true}>
                        <Header/>
                        <Switch>
                            <Route exact path="/">
                                <Welcome/>
                            </Route>
                            <Route path="/privacy">
                                <Privacy/>
                            </Route>
                            <Route path="/sign-in">
                                <SignIn/>
                            </Route>
                            <Route path="/how-it-works">
                                <HowItWorks/>
                            </Route>
                            <Route path="/contact-us">
                                <ContactUs/>
                            </Route>
                            <Route path="/customers">
                                <Customers/>
                            </Route>
                            <Route path="/pricing">
                                <Pricing/>
                            </Route>
                        </Switch>
                    </Container>
                </Router>
                <YMInitializer accounts={[65734693]} options={{webvisor: true}}  version="2"/>
            </React.Fragment>
        );

    }
}


export default Site;
